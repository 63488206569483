<template>
  <Dialog :name="name" width="600px">
    <template #title>
      <div>{{ titleText }}</div>
    </template>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="editForm"
      @submit.native.prevent
    >
      <div class="editForm__pickers">
        <div class="editForm__pickers-block">
          <div>{{ isMobile ? 'Время начала' : 'С' }}</div>

          <el-form-item class="timepicker" prop="time_from">
            <el-time-select
              v-model="ruleForm.time_from"
              :clearable="false"
              size="medium"
              :picker-options="pikerOptionsTimeFrom"
              class="timepicker__input"
              @focus="clearValidate('time_from')"
            />
          </el-form-item>
        </div>

        <div class="editForm__pickers-block">
          <div>{{ isMobile ? 'Время окончания' : 'по' }}</div>

          <el-form-item class="timepicker" prop="time_to">
            <el-time-select
              v-model="ruleForm.time_to"
              :clearable="false"
              size="medium"
              :disabled="isDisableTimeTo"
              :picker-options="pikerOptionsTimeTo"
              class="timepicker__input"
              @focus="clearValidate('time_to')"
            />
          </el-form-item>
        </div>
      </div>

      <el-form-item
        label-width="200px"
        label="Культура"
        size="medium"
        class="editForm__select"
      >
        <el-select
          v-model="ruleForm.culture_id"
          placeholder="Выберите культуру"
          :class="{ 'w-100p': isMobile }"
        >
          <el-option
            v-for="option in cultureList"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="200px"
        class="editForm__select"
        label="Поставщик"
        size="medium"
      >
        <el-select
          v-model="ruleForm.supplier_id"
          placeholder="Выберите поставщика"
          :class="{ 'w-100p': isMobile }"
        >
          <el-option
            v-for="option in suppliersList"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="200px"
        class="editForm__input"
        label="Количество авто в буфере"
        prop="count"
        :rules="rules.count"
      >
        <el-input-number
          v-model="ruleForm.count"
          controls-position="right"
          :min="1"
          size="medium"
          :class="{ 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item
        label-width="200px"
        label="Настроить тип авто"
        :rules="rules.truck_type_codes"
        size="small"
        prop="truck_type_codes"
      >
        <el-checkbox-group
          v-model="ruleForm.truck_type_codes"
          :class="['truck-types', { 'w-100p': isMobile }]"
        >
          <el-checkbox
            v-for="type in autoTypeSupervisor"
            :key="type.id"
            :label="type.val"
          >
            {{ type.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        label-width="200px"
        class="editForm__select"
        label="Линия"
        prop="unload_line_id"
        :rules="rules.unload_line_id"
        size="medium"
      >
        <el-select
          v-model="ruleForm.unload_line_id"
          placeholder="Укажите линию"
          :class="{ 'w-100p': isMobile }"
          @focus="clearValidate('unload_line_id')"
        >
          <el-option
            v-for="option in supervisorLinesOptions"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>

      <div class="editForm__submit">
        <iq-button class="editForm__submit-button" @onClick="handleSubmitForm">
          {{ buttonText }}
        </iq-button>
      </div>
    </el-form>
  </Dialog>
</template>

<script>
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  GET_IS_MOBILE,
  GET_SUPERVISOR_QUOTAS_LINES,
  SAVE_SUPERVISOR_SAMPLE,
} from '@/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { SUPERVISOR_EDIT_SAMPLE } from '@/constants/dialogs'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { mapActions, mapGetters } from 'vuex'
import { ruleAddSample } from './data'
import { timeConvert } from '@/core'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton.vue'
export default {
  name: 'EditSampleModal',
  components: { IqButton, Dialog },
  data() {
    return {
      name: SUPERVISOR_EDIT_SAMPLE,
      autoTypeSupervisor,
      ruleForm: {
        culture_id: 'all',
        supplier_id: undefined,
        count: 1,
        time_from: '',
        time_to: '',
        truck_type_codes: [],
        itemQuotaId: null,
        unload_line_id: null,
      },
      rules: ruleAddSample,
    }
  },
  computed: {
    ...mapGetters({
      culturesFromState: GET_CULTURE_FROM_STATE,
      suppliersFromState: GET_SUPPLIERS_ALL,
      linesFromState: GET_SUPERVISOR_QUOTAS_LINES,
      isMobile: GET_IS_MOBILE,
    }),
    itemQuota() {
      return this.getDialog(this.name)?.data || {}
    },
    titleText() {
      return this.itemQuota.isEdit
        ? 'Редактирование шаблона правил вызова'
        : 'Добавление шаблона правил вызова'
    },
    buttonText() {
      return this.itemQuota.isEdit ? 'Обновить и закрыть' : 'Добавить и закрыть'
    },
    pikerOptionsTimeFrom() {
      return {
        start: '00:00',
        step: '00:15',
        end: '23:45',
      }
    },
    pikerOptionsTimeTo() {
      return {
        start: '00:45',
        step: '00:15',
        end: '24:00',
        minTime: this.getMinTimeTo,
      }
    },
    isDisableTimeTo() {
      return !this.ruleForm.time_from
    },
    getMinTimeTo() {
      if (this.ruleForm.time_from) {
        let hours = Number(this.ruleForm.time_from.slice(0, 2))
        let minutes =
          Number(this.ruleForm.time_from.slice(3, 5)) + hours * 60 + 45

        return timeConvert(minutes)
      }

      return ''
    },
    allTruckTypesOptions() {
      return autoTypeSupervisor.map(item => item.val)
    },
    supervisorLinesOptions() {
      return this.linesFromState
        .slice()
        .reverse()
        .map((item, index) => ({
          id: item.id,
          name: `${index + 1} линия`,
        }))
    },
    cultureList() {
      let prepare = this.culturesFromState.map(item => ({
        id: item.id,
        name: `${item.name}  ${
          item.harvest_year ? item.harvest_year + ' года' : ''
        } `,
      }))

      return [
        {
          id: 'all',
          name: 'Любая культура',
        },
        ...prepare,
      ]
    },
    suppliersList() {
      return [{ id: null, name: 'Без поставщика' }, ...this.suppliersFromState]
    },
  },

  watch: {
    itemQuota: {
      immediate: true,
      handler(newVal) {
        this.setFormFields(
          newVal.culture_id,
          newVal.time_from,
          newVal.time_to,
          newVal.buffer_quota,
          // при создании квоты изначально отмечены все типы авто,
          // при редактировании - то, что заполнено в сохраненном шаблоне
          newVal.id
            ? newVal.truck_types.map(item => item.code)
            : this.allTruckTypesOptions,
          newVal.suppliers,
          newVal.id,
          newVal.unload_line_id,
        )
      },
    },
  },
  methods: {
    ...mapActions({
      saveSample: SAVE_SUPERVISOR_SAMPLE,
    }),
    handleSubmitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = {
            unload_line_id: this.ruleForm.unload_line_id,
            time_from: this.ruleForm.time_from,
            time_to:
              this.ruleForm.time_to === '24:00'
                ? '23:59'
                : this.ruleForm.time_to,
            suppliers_ids: this.ruleForm.supplier_id
              ? [this.ruleForm.supplier_id]
              : [],
            buffer_quota: this.ruleForm.count,
            truck_type_codes: this.ruleForm.truck_type_codes,
            culture_id:
              this.ruleForm.culture_id === 'all'
                ? null
                : this.ruleForm.culture_id,
            id: this.ruleForm.itemQuotaId,
          }

          this.saveSample(data)
          this.setDialog({ name: this.name, visible: false })
        }
      })
    },
    setFormFields(
      culture_id = 'all',
      time_from = '',
      time_to = '',
      count = 1,
      truck_type_codes = [],
      suppliers_ids = [],
      itemQuotaId,
      unload_line_id,
    ) {
      this.ruleForm.culture_id = culture_id === null ? 'all' : culture_id
      this.ruleForm.time_from = time_from
      this.ruleForm.time_to = time_to
      this.ruleForm.count = count
      this.ruleForm.truck_type_codes = truck_type_codes
      this.ruleForm.itemQuotaId = itemQuotaId
      this.ruleForm.unload_line_id = unload_line_id
      this.ruleForm.supplier_id = suppliers_ids[0]?.id || null
      this.$refs.ruleForm?.clearValidate()
    },
    beforeClose() {
      this.setFormFields()
    },
    clearValidate(field) {
      this.$refs.ruleForm.clearValidate(field)
    },
  },
}
</script>

<style lang="sass" scoped>
.editForm
  &__pickers
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    margin-bottom: 20px
    @media (max-width: 1199px)
      flex-direction: column
      align-items: flex-start
      margin-bottom: 10px

    &-block
      display: flex
      align-items: center
      gap: 10px
      flex: 0 1 50%
      @media (max-width: 1199px)
        flex-direction: column
        align-items: flex-start
        gap: 8px
        width: 100%

      .timepicker
        margin-bottom: 0 !important
        @media (max-width: 1199px)
          width: 100%
          margin-bottom: 10px !important
        :deep(.el-input__inner)
          padding-right: 10px
        .timepicker__input
          width: 252px
          @media (max-width: 1199px)
            width: 100%

  &__now
    margin: 15px 20px
    @media (max-width: 1199px)
      margin: -6px 0 10px
    &-button :deep(span)
      color: $btn-orange !important
      border-bottom: 1px solid $btn-orange !important

  &__select .el-select, &__input .el-input-number
    width: 100%

  .truck-types
    display: flex
    flex-direction: column

  &__submit
    display: flex
    justify-content: flex-end
    margin-top: 28px
    &-button
      width: 280px
      @media (max-width: 767px)
        width: 100%
</style>
